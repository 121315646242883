import { useEffect, useState } from "react";
import { NavLink, useParams} from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Helmet } from "react-helmet";

function Details() {
  const [isLoading, setisLoading] = useState(false);
  let params = useParams();
  const [data, setData] = useState([]);
  const [Img, setImg] = useState([]);


   useEffect(() => {
    fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/"+params.id,setisLoading(true),
      {
        method: "GET"
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data[0]);
        setisLoading(false)
      });
  
    });
    
    useEffect(() => {
        fetch(
        "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/Images/"+params.id,
        {
          method: "GET"
        }
        )
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            setImg(res.data);
            
        });
    });
    const  OurImge = Img.map((Imge) =>{

      return (
        
        <>
       <img height={"450px"} key={Imge.Path} src={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/product/"+Imge.Path} alt={Imge.Path} />
        </>
        
      );
    });

    return (
<>
<>
<Helmet>
          <title>{data.MetaOgTitle}</title>
          <meta property="og:title" content={data.MetaOgTitle} />
          <meta property="og:image" content={data.MetaOgImage} />
        </Helmet>
  {/* ======= Breadcrumbs Section ======= */}
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2>HPL</h2>
        <ol>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/Hpl">HPL</NavLink>
          </li>
          <li>{data.ProductName}</li>
        </ol>
      </div>
    </div>
  </section>
  {/* Breadcrumbs Section */}
  {/* ======= Portfolio Details Section ======= */}
  <section id="portfolio-details" className="portfolio-details">
    <div className="container" data-aos="fade-up">
      <div className="row gy-4">



        <div className="col-lg-6">
        <div className="App">
          <AliceCarousel autoPlay autoPlayInterval="1000">
            {OurImge}
          </AliceCarousel>
        </div>
          </div>
         
        <div className="col-lg-6">
          <div className="portfolio-info">
            <h3> {data.ProductName}</h3>
            <p>
            {data.MetaDescription}
            </p>
          </div>      
        </div>
      </div>
    </div>
  </section>
  {/* End Portfolio Details Section */}
</>

</>        
);
}
          
export default Details;