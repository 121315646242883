
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink   } from "react-router-dom";
function Brand1() {
  const [isLoading, setisLoading] = useState(true);
    const [data, setInfo] = useState([]);
    useEffect(() => {
      fetch(
        "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Catlog/Sanzolam",setisLoading(true)
        
        )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setisLoading(false)
          setInfo(res.data[0]);
        });
        
      }, []);
      return (
<>
  <Helmet>
          <title>{data.MetaOgTitle+" - Somya Touch Laminates"}</title>
          <meta property="keyword" content={data.MetaKeywords} />
          <meta property="description" content={data.MetaDescription} />
          <meta property="og:title" content={data.MetaOgTitle} />
          <meta property="og:image" content={data.MetaOgImage} />
          <meta property="og:description" content={data.MetaOgDescription} />
        </Helmet>
  {/* ======= Breadcrumbs Section ======= */}
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Sanzolam</h2>
        <ol>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>Sanzolam</li>
        </ol>
      </div>
    </div>
  </section>
  {/* Breadcrumbs Section */}
  {isLoading ? <div className=" row text-center mt-5 mb-5" style={{ justifyContent: "center" }}>
                                     <div class="lds-ripple"><div></div><div></div></div>
          </div> :
          <>  {/* ======= Portfolio Details Section ======= */}
          <section id="portfolio-details" className="portfolio-details">
            <div className="container" >
              <div className="row gy-4">
              <div className="col-lg-6">
                  <div className="portfolio-details-slider swiper">
                    <div className="swiper-wrapper align-items-center">
                        <img  src={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/"+data.BrandImage} className="img-fluid" alt={"somyatouch laminate "+data.BrandImage} />
                    </div>
                    <div className="swiper-pagination" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="portfolio-info">
                    <h3> {data.MetaOgTitle}</h3>
                    <div className="portfolio-description p-0">
                <p className="fst-italic">
                  {data.Description}
                </p>
            <ul>
              { data.BrandTitle1 && <li>
                <h5 />
                <i
                  style={{
                    fontSize: 20,
                    paddingRight: 4,
                    color: "#428bca"
                  }}
                  className="bi bi-check2-all"
                >
                  {data.BrandTitle1}
                </i>{data.BrandDescription1}
              </li>}
              { data.BrandTitle2 && <li>
                <h5 />
                <i
                  style={{
                    fontSize: 20,
                    paddingRight: 4,
                    color: "#428bca"
                  }}
                  className="bi bi-check2-all"
                >
                  {data.BrandTitle2}
                </i>{data.BrandDescription2}
              </li>}
            </ul>
          </div>
          <ul>
            <li>
                <a href={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/"+data.pdf} target="_blank" className="btn-visit btn-get-started align-self-start">PDF Download {data.MetaOgTitle}</a>
            </li>
          </ul>
                  </div>      
                </div>
              </div>
            </div>
          </section>
          {/* End Portfolio Details Section */}</>
          }
</>
    );
  }
  
  export default Brand1;