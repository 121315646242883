import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
function Footer() {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Contact/"
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data[0]);
      });
  }, []);
  const [Info, setInfo] = useState([]);
  useEffect(() => {
    fetch(
    "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/",
    
    )
    .then((res) => {
        return res.json();
    })
    .then((res) => {
        setInfo(res.data);
    });
}, []);
  return (
    <>
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-info">
             
                <h3>Somya Touch Laminates</h3>
                <p>
                  {data.Address}
                  <br />
                  <br />
                  <a href={"tel:"+data.ContactNumber1}>
                    <strong>Phone:</strong> {data.ContactNumber1}</a><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={"tel:"+data.ContactNumber2}>{data.ContactNumber2}</a>
                  
                  <br />
                  <a href={"mailto:"+data.Email}>
                    <strong>Email: </strong> {data.Email}
                  </a>
                    <br />
                    <a href={"mailto:"+data.Email2}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.Email2}
                  </a>
                </p>
                
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <NavLink to="/Home">Home</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <NavLink to="/About-us">About us</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <NavLink to="/Contact">Contact Us</NavLink>
                  </li>
                 
                  
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <NavLink to="/Our-product">Our Catlog</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <NavLink to="/Hpl">Our HPL</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <NavLink to="/Specifications">Specification</NavLink>
                  </li>
                </ul>
              </div>
              {/* <div className="col-lg-4 col-md-6 footer-newsletter"> */}
                <div class="col-lg-4 col-md-6 footer-links">
                  <img
                    class="img-fluid"
                    alt="Make In India"
                    src="https://ketonelaminates.com/assets/img/Make-in-ndia-Logo-PNG-HD-Transparent.png"
                  />
                  <div className="social-links mt-3 mx-4">
                  {/* <a href="#" className="twitter">
                <i className="bx bxl-twitter" />
              </a> */}
              <div className="row">
                <div className="col-3">
                <a href={data.FacebookLink} target="_blank" rel="noreferrer" className="facebook ">
                    <i className="bi bi-facebook"></i>
                  </a>
                </div>
                <div className="col-3">
                <a href={data.InstagramLink} target="_blank" rel="noreferrer" className="instagram ">
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
                <div className="col-3">
                <a href={data.WhatsAppLink} target="_blank" rel="noreferrer" className="whatsaap ">
                    <i className="bi bi-whatsapp"></i>
                  </a>
                </div>
                <div className="col-3">
                <a href={"tel:"+data.ContactNumber1}  rel="noreferrer" className="phone-number ">
                    <i className="bi bi-phone-fill phone-icon"></i>
                  </a>
                </div>
              </div>
                  
                  
                  
                  
                </div>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
}

export default Footer;
