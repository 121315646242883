import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
function Hpl() {
    const [isLoading, setisLoading] = useState(true);
    const [Info, setInfo] = useState([]);
    useEffect(() => {
      fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/",setisLoading(true)
      
      )
      .then((res) => {
          return res.json();
      })
      .then((res) => {
          setInfo(res.data);
          setisLoading(false);
      });
  }, []);
  
  const  OurHpl = Info.map((Product) =>{
    return (
      <>
      <style
      dangerouslySetInnerHTML={{
            __html:
        '.igm{ height="280px !important" }'
      }}
      />
         <div className="col-xl-3 col-lg-4 col-md-6" >
          <div className="member">
            <div className="pic">
              <NavLink to={"/Product/"+Product.ProductUrl} >

              <img
                src={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/product/"+Product.Path}
                className="img-flid igm" height={"280px !important"}
                alt={"somyatouch Laminate"+Product.Path+" "+Product.ProductUrl}
                />
                </NavLink>
            </div>
            <div className="member-info">
              <h4>{Product.ProductName}</h4>
            </div>
          </div>
        </div>
      </>
    );
  });
    return (
<>
<Helmet>
          <title>HPL - Somya Touch Laminates</title>
          <meta property="keyword" content={Info.MetaKeywords} />
          <meta property="description" content={Info.MetaDescription} />
          <meta property="og:title" content={Info.MetaOgTitle} />
          <meta property="og:image" content={Info.MetaOgImage} />
          <meta property="og:description" content={Info.MetaOgDescription} />
        </Helmet>
  <section id="team" className="team">
    <div className="container" >
      <div className="section-title">
        <h2>Our Hpl</h2>
      </div>
      <div className="row">
      {isLoading && <div className=" row text-center" style={{ justifyContent: "center" }}>
                                     <div class="lds-ripple"><div></div><div></div></div>
                                          </div> }
      {OurHpl}
      </div>
    </div>
  </section>
  {/* End Our Team Section */}
</>

    );
  }
  
  export default Hpl;