import { useEffect, useState } from "react";
import About from "../ProductPage/About";
import Services from "../ProductPage/Services";
import { Helmet } from "react-helmet";
import ProdustsDetails from "../DetailsPage/ProdustsDetails";
import Hpl from "../DetailsPage/Hpl";

function Home() {
  const [isLoading, setisLoading] = useState(true);
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }
    const [Info, setInfo] = useState([]);
    const [Img, setImg] = useState([]);
    useEffect(() => {
      fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Slider/",
      
      )
      .then((res) => {
          return res.json();
      })
      .then((res) => {
          setInfo(res.data);
      });
  }, []);
useEffect(() => {
  fetch(
  "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/Images/Door",
  
  )
  .then((res) => {
      return res.json();
  })
  .then((res) => {
      setImg(res.data);
  });

});
const  OurHpl = Img.map((Product) =>{
console.log(123)
return (
  <>
     <div className="swiper-slide">
              <img src="https://reactjsexample.com/content/images/2021/11/react-swipe-component.png" alt="tyhty" />
            </div>
  </>
);
});
var flag = true;
var v = "";
const  Slider = Info.map((slide) =>{

  return (
    <>
    {v = flag == true ?"active":""}
      <div
            className={"carousel-item   "+v}
            style={{ backgroundImage: "url(https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/slider/" + slide.Image + ")" }}
          >
            <div className="carousel-container">
              <div className="carousel-content container">
                <h2 className="animate__animated animate__fadeInDown">
                  {slide.SlideTitle}  {flag = false}
                  
                </h2>
                <p className="animate__animated animate__fadeInUp">
                <div dangerouslySetInnerHTML={{__html: slide.SlideDescription}}></div>
                </p>
                 
              </div>
            </div>
          </div>
    </>
  );
});


  return (
    <>
  {/* ======= Hero Section ======= */}
  <section id="hero">
    <div className="hero-container">
      <div
        id="heroCarousel"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval={9000}
      >
        <ol className="carousel-indicators" id="hero-carousel-indicators" />
        <div className="carousel-inner" role="listbox">
        {Slider}
        </div>
        <a
          className="carousel-control-prev"
          href="#heroCarousel"
          role="button"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon bi bi-chevron-left"
            aria-hidden="true"
          />
        </a>
        <a
          className="carousel-control-next"
          href="#heroCarousel"
          role="button"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon bi bi-chevron-right"
            aria-hidden="true"
          />
        </a>
      </div>
    </div>
  </section>
  {/* End Hero */}
  <Hpl/>
   <About/>
   <ProdustsDetails/>
   <Services/>
   <Helmet>
          <title>Somya Touch Laminates</title>
          <meta property="keyword" content={Info.MetaKeywords+" Somya Touch Laminates HPL Catelog Specification Shreyans Padmani Jay shanghani"} />
          <meta property="description" content="Somya Touch Laminates " />
          <meta property="og:title" content="Somya Touch Laminates tit" />
          <meta property="og:image" content="https://somyatouchlaminate.com/assets/img/logo192.png" />
          <meta property="og:description" content="Somya Touch Laminates dec" />
        </Helmet>
</>

    
  );
}

export default Home;