import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './Layout/Header'
import reportWebVitals from './reportWebVitals';
import Home from './Layout/Home';
import Contct from './ProductPage/Contct';
import About from './ProductPage/About';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import ProdustsDetails from './DetailsPage/ProdustsDetails';
import Specifications from './DetailsPage/Specifications';
import Footer from './Layout/Footer';
import Details from './DetailsPage/Details';
import CatelogDetails from './DetailsPage/CatelogDetails';
import Hpl from './DetailsPage/Hpl';
import Demo from './Demo';
import Aboutus from './ProductPage/Aboutus';
import Brand1 from './Brand/Brand1';
import Brand2 from './Brand/Brand2';
import Brand3 from './Brand/Brand3';


function Layout(){
  return(
      <>
          <Header />
          <Outlet/>
          <Footer/>
      </>
  );
}
export default function App() {
  return (
      <BrowserRouter>
      <Routes basename="/" > 
            <Route  element={<Layout />}>
            <Route key="ghar" index path='/'  element={<Home key="ghar"/>}/>
            <Route key="demo" index path='/Demo'  element={<Demo key="demo"/>}/>
            <Route key="about"  path='About-us' element={<Aboutus key="about"/>}/>
            <Route key="details"  path='Product/:id' element={<Details key="details"/>}/>
            <Route key="Brand"  path='Brand/Sanzolam' element={<Brand1 key="Brand"/>}/>
            <Route key="BrandDoorSkin"  path='Brand/Door-Skin' element={<Brand2 key="BrandDoorSkin"/>}/>
            <Route key="BrandPlainColor"  path='Brand/Plain-Color' element={<Brand3 key="BrandDPlainColor"/>}/>
            <Route key="catelogdetails"  path='/Catlog/:id' element={<CatelogDetails key="catelogdetails"/>}/>
            <Route key="hpl"  path='Hpl' element={<Hpl key="hpl"/>}/>
            <Route key="ourproduct"  path='Our-product' element={<ProdustsDetails key="ourproduct"/>}/>
            <Route key="specification" path='Specifications' element={<Specifications key="specification"/>}/>
              <Route key="contact"  path='Contact' element={<Contct key="contact"/>}/>
              <Route key="home" path='Home' element={<Home key="home"/>}/>
            </Route>
      </Routes>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
