import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
function ProdustsDetails() {
  const [isLoading, setisLoading] = useState(true);
    const [Info, setInfo] = useState([]);
    useEffect(() => {
      fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Catlog/",setisLoading(true),
      
      )
      .then((res) => {
          return res.json();
      })
      .then((res) => {
          setInfo(res.data);
          setisLoading(false)
      });
  }, []);
  const  OurProduct = Info.map((Product) =>{
    return (
      <>
         <div className="col-lg-4 col-md-6 portfolio-item ">
          <div className="portfolio-wrap">
            <img
               src={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/"+Product.Images}
               height={"500px !important"}
              className="img-fluid"
              alt={"somyatouch Laminate"+Product.Images}
            />
            <div className="portfolio-info">
              <h4>{Product.CatlogUrl}</h4>
              <div className="portfolio-links">
              {/* <NavLink
                to={"/Catlog/"+Product.CatlogUrl}
                data-gallery="portfolioGallery"
                className="portfolio-lightbox"
                title="App 1"
              >
                <i className="bi bi-plus" />
              </NavLink> */}
                <a href={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/"+Product.pdf} target="_blank"  title="More Details">
                  <i className="bi bi-link" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  });
    return (
<>
<Helmet>
          <title>Catlog - Somya Touch Laminates</title>
          <meta property="keyword" content={Info.MetaKeywords} />
          <meta property="description" content={Info.MetaDescription} />
          <meta property="og:title" content={Info.MetaOgTitle} />
          <meta property="og:image" content={Info.MetaOgImage} />
          <meta property="og:description" content={Info.MetaOgDescription} />
        </Helmet>
  {/* ======= Our Portfolio Section ======= */}
  <section id="portfolio" className="portfolio section-bg">
    <div className="container"  data-aos-delay={100}>
      <div className="section-title">
        <h2>OUR Catlog</h2>
      </div>
      <div className="row portfolio">
      {isLoading && <div className="text-center" style={{ justifyContent: "center" }}>
                                     <div class="lds-ripple"><div></div><div></div></div>
                                          </div> }
       {OurProduct}
      </div>
    </div>
  </section>
  {/* End Our Portfolio Section */}
</>

    );
  }
  
  export default ProdustsDetails;