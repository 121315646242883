import { useEffect, useState } from "react";
import { NavLink, useParams} from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import { Helmet } from "react-helmet";
import 'react-alice-carousel/lib/alice-carousel.css';

function Details() {
  const [isLoading, setisLoading] = useState(true);
  let params = useParams();
  const [data, setData] = useState([]);
  const [Img, setImg] = useState([]);


   useEffect(() => {
    fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Catlog/"+params.id,
      {
        method: "GET"
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data[0]);
      });
  
    });
    

    return (
<>
<>
<Helmet>
          <title>{data.MetaOgTitle+ " - Somya Touch Laminates"}</title>
          <meta property="og:title" content={data.MetaOgTitle} />
          <meta property="og:image" content={data.MetaOgImage} />
        </Helmet>
  {/* ======= Breadcrumbs Section ======= */}
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Catlog Details</h2>
        <ol>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/Our-product">Catlog</NavLink>
          </li>
          <li>Catlog Details</li>
        </ol>
      </div>
    </div>
  </section>
  {/* Breadcrumbs Section */}
  {/* ======= Portfolio Details Section ======= */}
  <section id="portfolio-details" className="portfolio-details">
    <div className="container" data-aos="fade-up">
      <div className="row gy-4">
      <div className="col-lg-6">
          <div className="portfolio-details-slider swiper">
            <div className="swiper-wrapper align-items-center">
                <img  src={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/"+data.BrandImage} className="img-fluid" alt="" />
            </div>
            <div className="swiper-pagination" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="portfolio-info">
            <h3> {data.CatlogUrl}</h3>
            <p>
            {data.Description}
            </p>
          </div>      
        </div>
      </div>
    </div>
  </section>
  {/* End Portfolio Details Section */}
</>

</>        
);
}
          
export default Details;