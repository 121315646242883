import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
function Specifications() {
return (
    <>
    <Helmet>
          <title>Specifications - Somya Touch Laminates</title>
        </Helmet>
  <section id="services" className="services section-bg">
    <div className="container" >
    <div className="section-title">
            <h2>Our Specifications</h2>
          </div>
      <div className="row ">
        <div
          className="col-lg-3 col-md-6"
          data-aos-delay={100}
          data-aos="fade-up"
        >
          <div className="service-item  position-relative">
            <div className="icon">
              <i className="fa-solid bi-basket3-fill" />
            </div>
            <h3>Product Portfolio</h3>
            <p></p>
            <div className="row">
              <div className="col-lg-12">
                ● Hpl Sheet<br />
                ● Decorative &nbsp;Laminates <br />
                ● Door Skin<br />
                ● Digital Laminates <br />
              </div>
            </div>
            <p />
          </div>
        </div>
        {/* End Service Item */}
        <div
          className="col-lg-3 col-md-6"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="service-item position-relative">
            <div className="icon">
              <i className="bi bi-boxes" />
            </div>
            <h3>Size</h3>
            <p />
            <div className="row">
              <div className="col-sm-12">
                ● 7ft x 3.25ft
                <br />
                ● 8ft x 4ft<br />
              </div>
            
            </div>
            <p />
          </div>
        </div>
        {/* End Service Item */}
        <div
          className="col-lg-3 col-md-6"
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <div className="service-item position-relative">
            <div className="icon">
              <i className="bi-solid bi-building" />
            </div>
            <h3>Available Textures</h3>
            <p>
              ●  Matt <br />● High Gloss 
              <br />● SF
            </p>
          </div>
        </div>
        {/* End Service Item */}
        {/* End Service Item */}
        <div
          className="col-lg-3 col-md-6"
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <div className="service-item position-relative">
            <div className="icon">
              <i className="bi-solid bi-brightness-high" />
            </div>
            <h3>Thickness</h3>
            <p>● 0.72mm, 0.82mm, 0.92mm <br/>
            ● 1mm, 2.1mm</p>
          </div>
        </div>
        {/* End Service Item */}
      </div>
    </div>
  </section>
  {/* ======= Contact Section ======= */}
  {/* ======= Contact Section ======= */}
  <section id="contact" className="contact">
    <h2 className="customers text-center">Technical Specification</h2>
    <br />
    <div className="container table-responsive"  data-aos-delay={100}>
      <div className="elementor-text-editor elementor-clearfix">
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n        #customers {\n        font-family: "Open Sans", Arial, Helvetica, sans-serif;\n        border-collapse: collapse;\n        width: 100%;\n        }\n\n        #customers td, #customers th {\n        border: 1px solid #0092ff;\n        padding: 10px 15px 10px 15px;\n        }\n\n        #customers th {\n        padding-top: 12px;\n        padding-bottom: 12px;\n        text-align: center;\n        background-color: #abdbff;\n        color: #292929;\n        font-size: 15px;\n        font-family: "Poppins", Arial, Helvetica, sans-serif;\n        font-weight: 500;\n        }\n\n        #customers td {\n        text-align: center;\n        color: #1a1a1a;\n        font-size: 15px;\n        }\n        '
          }}
        />
       <table id="customers" data-aos="fade-up">
                <tbody><tr>
                    <th>PROPERTY HGS TYPE</th>
                    <th>1S2046-95 HUTCH HGS</th>
                    <th>TYPICAL VALUE VGS TYPE</th>
                    <th>1S2046/95 TYPICAL VALUE</th>
                    
                </tr>
                <tr>
                    <td>Thickness Tolerance</td>
                    <td>10%</td>
                    <td>10%</td>
                    <td>+10% 10%</td>
                    
                </tr>
                <tr>
                    <td>Appearance</td>
                    <td>No ABC Defect</td>
                    <td>No ABC Defect</td>
                    <td>No ABC Defect No ABC Defect</td>
                </tr>
                <tr>
                    <td>Surface Wear Resistance (cycle)</td>
                    <td>350</td>
                    <td>400</td>
                    <td>150 150</td>
                </tr>
                <tr>
                    <td>Boiling Wear Resistance</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Thickness Increase (max)</td>
                    <td>12%</td>
                    <td>8%</td>
                    <td>12% 8%</td>
                </tr>
                <tr>
                    <td>Weight Increase (max)</td>
                    <td>10%</td>
                    <td>8%</td>
                    <td>10% 8%</td>
                </tr>
                <tr>
                    <td>High Temperature Resistance</td>
                    <td>Slight Effect</td>
                    <td>Slight Effect</td>
                    <td>Slight Effect Slight Effect</td>
                </tr>
                <tr>
                    <td>Satin Resistance</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Reagents 1 and 2</td>
                    <td>No Effect</td>
                    <td>No Effect</td>
                    <td>No Effect No Effect</td>
                </tr>
                <tr>
                    <td>Reagents 3 and 4</td>
                    <td>Slight Effect</td>
                    <td>Slight Effect</td>
                    <td>Slight Effect Slight Effect</td>
                </tr>
                <tr>
                    <td>Small Dia Ball Resistance</td>
                    <td>20N</td>
                    <td>22N</td>
                    <td>15N 17.5N</td>
                </tr>
                <tr>
                    <td>Dimensional Stability MD (max)</td>
                    <td>0.50%</td>
                    <td>0.34%</td>
                    <td>0.70% 0.50%</td>
                </tr>
                <tr>
                    <td>CD (max)</td>
                    <td>0.80%</td>
                    <td>0.70%</td>
                    <td>0.80% 0.70%</td>
                </tr>
                <tr>
                    <td>Resistance To Dry Heat (180)</td>
                    <td>Slight Change</td>
                    <td>Slight Change</td>
                    <td>Slight Change Slight Change</td>
                </tr>
                 <tr>
                    <td>Resistance to Cigarette Burn</td>
                    <td>Moderate Change</td>
                    <td>Moderate Change</td>
                    <td>Moderate Change Moderate Change</td>
                </tr>
            </tbody>
        </table>

      </div>
    </div>
  </section>
  {/* End Contact Section */}

    </>
    
        );
      }
export default Specifications;