import { useEffect, useState } from "react";
import About from "./About";
import { Helmet } from "react-helmet";
import Services from "./Services";
import { NavLink } from "react-router-dom";
function Aboutus() { 
  const [Info, setInfo] = useState([]);
  useEffect(() => {
    fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/About/",
      
      )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setInfo(res.data[0]);
      });
      
    }, []);
  const [isLoading, setisLoading] = useState(true);
  return (
    <>
     <Helmet>
          <title>{Info.MetaOgTitle}</title>
          <meta property="og:title" content={Info.MetaOgTitle} />
          <meta property="og:image" content={Info.MetaOgImage} />
        </Helmet>
      {/* ======= Breadcrumbs Section ======= */}
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2>About us</h2>
        <ol>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>About us</li>
        </ol>
      </div>
    </div>
  </section>
  {/* Breadcrumbs Section */}
    <About/>
    <Services/>
     </>
        );
      }
      
export default Aboutus;