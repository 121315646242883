import { useEffect, useState } from "react";

function About() {
  const [isLoading, setisLoading] = useState(true);
    const [Info, setInfo] = useState([]);
    useEffect(() => {
      fetch(
        "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/About/",setisLoading(true)
        
        )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setisLoading(false)
          setInfo(res.data[0]);
        });
        
      }, []);
      return (
<>
{isLoading ? <div className=" row text-center mt-5 mb-5" style={{ justifyContent: "center" }}>
                                     <div class="lds-ripple"><div></div><div></div></div>
          </div> :  
 <section id="about" className="about">
    <div className="container" >
      <div className="row no-gutters">
        <div className="col-lg-6 video-box">
          <img src={"https://somyatouchlaminate.com/ad_somyatouchlaminate/Images/user/"+Info.AboutPhoto} className="img-fluid" alt="" />
        
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
          <div className="section-title">
            <h2>{Info.AboutTitle}</h2>
            <p>
            <div dangerouslySetInnerHTML={{__html: Info.AboutDescription}}></div>
            </p>
          </div>
          <div className="icon-box"  data-aos-delay={100}>
            <div className="icon">
              <i className="bx bx-fingerprint" />
            </div>
            <h4 className="title">
              <a href="#">{Info.AboutTitle2}</a>
            </h4>
            <p className="description">
            <div dangerouslySetInnerHTML={{__html: Info.AboutDescription2}}></div>
            </p>
          </div>
          <div className="icon-box"  data-aos-delay={100}>
            <div className="icon">
              <i className="bx bx-gift" />
            </div>
            <h4 className="title">
              <a href="#">{Info.AboutTitle3}</a>
            </h4>
            <p className="description">
            <div dangerouslySetInnerHTML={{__html: Info.AboutDescription3}}></div>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  }

  {/* ======= Counts Section ======= */}
  <section className="counts section-bg">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 text-center" >
          <div className="count-box">
            <i className="bi bi-simple-smile" style={{ color: "#20b38e" }} />
            <span
              data-purecounter-start={0}
              data-purecounter-end={232}
              data-purecounter-duration={1}
              className="purecounter"
            >{Info.Clients}</span>
            <p>Happy Clients</p>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 text-center"
          
          data-aos-delay={200}
        >
          <div className="count-box">
            <i className="bi bi-document-folder" style={{ color: "#c042ff" }} />
            <span
              data-purecounter-start={0}
              data-purecounter-end={521}
              data-purecounter-duration={1}
              className="purecounter"
            >{Info.Countries}</span>
            <p>Countries</p>
          </div>
        </div>
        <div
          className="col-lg-4 col-md-6 text-center"
          
          data-aos-delay={400}
        >
          <div className="count-box">
            <i className="bi bi-live-support" style={{ color: "#46d1ff" }} />
            <span
              data-purecounter-start={0}
              data-purecounter-end={1463}
              data-purecounter-duration={1}
              className="purecounter"
            >{Info.Experience}</span>

            <p>Experience</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Counts Section */}
  {/* ======= About Lists Section ======= */}
  <section className="about-lists">
    <div className="container">
      <div className="row no-gutters">
        <div className="col-lg-4 col-md-6 content-item" >
          <span>01</span>
          <h4>High-quality laminates.</h4>
          <p>
          We provide high-quality laminates for furniture, exterior grad cladding, walls, and countertops with exceptional durability, strength, and design variety, while also being eco-friendly.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 content-item"
          
          data-aos-delay={100}
        >
          <span>02</span>
          <h4>Advanced technology and techniques to produce laminates</h4>
          <p>
          Our laminates are made using only the highest quality materials, ensuring that they are able to withstand the test of time.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 content-item"
          
          data-aos-delay={200}
        >
          <span>03</span>
          <h4> we strive to minimize our environmental</h4>
          <p>
          Our laminates are easy to install and maintain, making them a cost-effective option for any project.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 content-item"
          
          data-aos-delay={300}
        >
          <span>04</span>
          <h4>Helping you find the perfect laminate
</h4>
          <p>
          We are committed to helping you find the perfect laminate for your project and ensuring that you are completely satisfied with your purchase.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 content-item"
          
          data-aos-delay={400}
        >
          <span>05</span>
          <h4>Customers are completely satisfied</h4>
          <p>
            
We are committed to ensuring that our customers are completely satisfied with their laminates and our services.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 content-item"
          
          data-aos-delay={500}
        >
          <span>06</span>
          <h4>
Sustainable practices and materials</h4>
          <p>
            
We are committed to using sustainable practices and materials to minimize our environmental impact.
          </p>
        </div>
      </div>
    </div>
  </section>
  {/* End About Lists Section */}
</>

    );
  }
  
  export default About;