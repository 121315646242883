import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
function Services() {
  const [isLoading, setisLoading] = useState(true);
    const [Info, setInfo] = useState([]);
    useEffect(() => {
      fetch(
        "https://uniqueadmin.somyatouchlaminate.com/ad_somyatouchlaminate/Api/About/",
        
        )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setInfo(res.data[0]);
        });
        
      }, []);
      return (
<>
  {/* ======= Services Section ======= */}
  <section id="services" className="services">
    <div className="container" >
      <div className="section-title">
        <h2>Services</h2>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 icon-box" >
          <div className="icon">
            <i className="bi bi-chat-left-dots" />
          </div>
          <h4 className="title">
            <a href="">Design Consultation</a>
          </h4>
          <p className="description">
          Our experienced team can help you select the perfect laminate for your project based on your design preferences, durability requirements, and budget.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          
          data-aos-delay={100}
        >
          <div className="icon">
            <i className="bi bi-bounding-box" />
          </div>
          <h4 className="title">
            <a href="">Repair and Maintenance</a>
          </h4>
          <p className="description">
          We offer repair and maintenance services to help you keep your laminates in top condition.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          
          data-aos-delay={200}
        >
          <div className="icon">
            <i className="bi bi-globe" />
          </div>
          <h4 className="title">
            <a href="">Anti-Static Coating</a>
          </h4>
          <p className="description">
          We offer laminates with anti-static coating to reduce the buildup of static electricity. 
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          
          data-aos-delay={300}
        >
          <div className="icon">
            <i className="bi bi-broadcast" />
          </div>
          <h4 className="title">
            <a href="">Anti-Bacterial Laminates</a>
          </h4>
          <p className="description">
          We offer laminates with anti-bacterial properties to help prevent the growth of bacteria and other microorganisms. 
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          
          data-aos-delay={400}
        >
          <div className="icon">
            <i className="bi bi-brightness-high" />
          </div>
          <h4 className="title">
            <a href="">Sound Absorption</a>
          </h4>
          <p className="description">
          We offer laminates with sound-absorbing properties to help reduce noise levels in areas such as offices, schools, and hospitals.
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          
          data-aos-delay={500}
        >
          <div className="icon">
            <i className="bi bi-calendar2-week" />
          </div>
          <h4 className="title">
            <a href="">Custom Cutting</a>
          </h4>
          <p className="description">
          We offer custom cutting services to ensure that your laminates fit perfectly in your space.
          </p>
        </div>
      </div>
    </div>
  </section>
  {/* End Services Section */}
</>


    );
  }
  
  export default Services;