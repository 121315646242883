import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
function Contct() {
  const [isLoading, setisLoading] = useState(true);
    var myObj = document.getElementById("smsg");
    var myObjl = document.getElementById("load");
    function msg(){
      myObj.style.display = "block";
      
      myObj.classList.add('animate__jackInTheBox');
      var Name=document.getElementById ("Name");
      Name.value="";
      var Email=document.getElementById ("Email");
      Email.value="";
      var ContactNumber=document.getElementById ("ContactNumber");
      ContactNumber.value="";
      var Subject=document.getElementById ("Subject");
      Subject.value="";
      var Description=document.getElementById ("Description");
      Description.value="";
      setTimeout(myFunction, 2500)
    }
    function myFunction() {
      myObj.style.display = "none"; 
    }
    function sent(){
      myObjl.style.display = "block";
      
    }
    function mysetFunction() {
      myObjl.style.display = "none"; 
    }
    const [data, setData] = useState([]);
    const [Info, setInfo] = useState([]);
    useEffect(() => {
      fetch(
      "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Contact/",setisLoading(true)
      
      )
      .then((res) => {
          return res.json();
      })
      .then((res) => {
          setInfo(res.data[0]);
        setisLoading(false)
      });
  
  }, []);
    return (
 
        <>
           <Helmet>
          <title>{data.MetaOgImage}</title>
          <meta property="keyword" content={data.MetaKeywords} />
          <meta property="description" content={data.MetaDescription} />
          <meta property="og:title" content={data.MetaOgTitle} />
          <meta property="og:image" content={data.MetaOgImage} />
          <meta property="og:description" content={data.MetaOgDescription} />
        </Helmet>
          {/* ======= Breadcrumbs Section ======= */}
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Contact Us</h2>
        <ol>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>Contact Us</li>
        </ol>
      </div>
    </div>
  </section>
  {/* Breadcrumbs Section */}

        {/* ======= Contact Us Section ======= */}
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact Us</h2>
            </div>
            {isLoading && <div className="text-center" style={{ justifyContent: "center" }}>
                                     <div class="lds-ripple"><div></div><div></div></div>
                                          </div> }
            <div className="row">
              <div className="col-lg-6 d-flex" data-aos="fade-up">
                <div className="info-box">
                  <i className="bx bx-map" />
                  <h3>Our Address</h3>
                  <p>{Info.Address}</p>
                </div>
              </div>
              <div
                className="col-lg-3 d-flex"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="info-box">
                  <i className="bx bx-envelope" />
                  <h3>Email Us</h3>
                  <p>
                  {Info.Email}
                    <br />
                    {Info.Email2}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 d-flex"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="info-box ">
                  <i className="bx bx-phone-call" />
                  <h3>Call Us</h3>
                  <p>
                    {Info.ContactNumber1}
                    <br />
                    {Info.ContactNumber2}
                  </p>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay={300}>
              <iframe
              src={Info.GoogleMapLink}
              frameBorder={0}
              style={{ border: 0, width: "100%", height: 384 }}
              allowFullScreen=""
              loading="lazy"
            />
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay={300}>
                <form
                  
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        name="Name"
                        className="form-control"
                        id="Name"
                        placeholder="Your Name"
                        required="" value={data.Name}
                        onChange={(e) => {
                            setData({ ...data, Name: e.target.value });
                          }}
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <input
                        type="Email"
                        className="form-control"
                        name="Email"
                        id="Email"
                        placeholder="Your Email"
                        required="" value={data.Email}
                        onChange={(e) => {
                            setData({ ...data, Email: e.target.value });
                          }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        name="ContactNumber"
                        className="form-control"
                        id="ContactNumber"
                        placeholder="Your Contact Number"
                        required="" value={data.ContactNumber}
                        onChange={(e) => {
                            setData({ ...data, ContactNumber: e.target.value });
                          }}
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="Subject"
                        id="Subject"
                        placeholder="Subject"
                        required="" value={data.Subject}
                        onChange={(e) => {
                            setData({ ...data, Subject: e.target.value });
                          }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                    id="Description"
                      className="form-control"
                      name="Description"
                      rows={5}
                      placeholder="Description"
                      required=""
                      defaultValue={""} value={data.Description}
                      onChange={(e) => {
                        setData({ ...data, Description: e.target.value });
                      }}
                    />
                  </div>
                  <div className="my-3">
                     <div className="loading" id="load">Loading...</div>
                    
                    <div className="error-message" />
                    <div className="sent-message" id="smsg">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="button" onClick={(e)=>{
                      sent();
                        fetch(
                        "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Feedback/" ,
                        {
                            method: "POST",
                            body: JSON.stringify(data),
                            // headers: {
                            //   "Content-Type": "application/json"
                            // }
                        }
                        ).then(() => {
                          mysetFunction();
                        msg();
                        });
                }}>Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Us Section */}
      </>
      
      
    );
  }
  
  export default Contct;