import React, {useEffect,  useState } from "react";
import { useParams} from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export default function Demo() {
  let params = useParams();
  const [data, setData] = useState([]);
  const [Img, setImg] = useState([]);


   useEffect(() => {
    fetch(
      "https://uniqueadmin.somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/"+params.id,
      {
        method: "GET"
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data[0]);
      });
  
    });
    
    useEffect(() => {
        fetch(
        "https://uniqueadmin.somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/Images/"+params.id,
        {
          method: "GET"
        }
        )
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            setImg(res.data);
        });
    });
    const  OurImge = Img.map((Imge) =>{

      return (
        
        <>
       <img src={"https://uniqueadmin.somyatouchlaminate.com/ad_somyatouchlaminate/Images/product/"+Imge.Path} alt={Imge.Path} />
        </>
        
      );
    });
    
  return (
    <>
    <div className="row" >
        <div className="col-md-6 p-5 ">
        <div className="App">
          <AliceCarousel autoPlay autoPlayInterval="1000">
            {OurImge}
          </AliceCarousel>
        </div>
        </div>
        <div className="col-md-6">
        <div className="portfolio-info">
            <h3> {data.ProductName}</h3>
            <p>
            {data.MetaDescription}
            </p>
          </div> 
        </div>
    </div>
    </>
  );
}