import React  from "react";
import { NavLink ,useNavigate} from "react-router-dom";
import { useEffect,  useState } from "react";
function Header() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(
    "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Contact/",
    
    )
    .then((res) => {
        return res.json();
    })
    .then((res) => {
      setData(res.data[0]);
    });

}, []);

  //use efffecct
  const [Info, setInfo] = useState([]);
  useEffect(() => {
    fetch(
    "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Product/",
    
    )
    .then((res) => {
        return res.json();
    })
    .then((res) => {
        setInfo(res.data);
    });
}, []);
  //use efffecct
  const [Infocatelog, setInfocatelog] = useState([]);
  useEffect(() => {
    fetch(
    "https://somyatouchlaminate.com/ad_somyatouchlaminate/Api/Catlog/",
    
    )
    .then((res) => {
        return res.json();
    })
    .then((res) => {
        setInfocatelog(res.data);
    });
}, []);
const  MenuProduct = Info.map((Product) =>{
  return (
    <>
       <li key={Product.ProductUrl} ><NavLink  onClick={() => { 
                    navigate("Product/"+Product.ProductUrl) 
                  }}>{Product.ProductName}</NavLink></li>
    </>
  );
});

const  MenuCatelog = Infocatelog.map((Product) =>{
  return (
    <>
       <li key={Product.CatlogUrl} ><NavLink to={"Product/"+Product.CatlogUrl} >{Product.CatlogUrl}</NavLink></li>
    </>
  );
});
  var x = window.matchMedia("(max-width: 990px)");
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }
  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

    return(
        <>
        {/* <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope-fill"></i><a href="mailto:contact@example.com">{data.Email}</a>
            <i className="bi bi-phone-fill phone-icon"></i><a href="tel:+917874579457">
               {data.ContactNumber1}
              </a>
          </div>
          <div className="social-links d-none d-md-block">
            <a href={""+data.FacebookLink} target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
            <a href={""+data.InstagramLink} target="_blank" className="instagram"><i className="bi bi-instagram"></i></a>
            <a href={""+data.WhatsappLink} target="_blank" className="whatsaap"><i className="bi bi-whatsapp"></i></a>
            <a href={"tel:+917874579457"+data.ContactNumber} className="phone-number"><i className="bi bi-phone-fill phone-icon"></i></a>
          </div>
        </div>
      </section> */}
  <header id="header" className="d-flex align-items-center">
    <div className="container d-flex align-items-center">

      <div className="logo me-auto">
        {/* <h1><a href="index.html">Mamba</a></h1> */}
        <NavLink to="/" className="f-40 #926a18" style={{ color: "#917216" }}  >
          <img src="assets/img/logo192.png" alt="" className="img-fluid"/> <span>Somya Touch Laminates</span> </NavLink>
      </div>

      <nav id="navbar" className="navbar">
        <ul>
          <li><NavLink className="nav-link scrollto active" to="/" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Home</NavLink></li>
         
          <li><NavLink className="nav-link scrollto" to="Hpl" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Hpl</NavLink></li>
          <li><NavLink className="nav-link scrollto" to="About-us" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>About</NavLink></li>
          <li><NavLink className="nav-link scrollto" to="Specifications" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Specifications</NavLink></li>
          <li><NavLink className="nav-link scrollto" to="Our-product" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Our Catelog</NavLink></li>
          {/* <li className="dropdown"><a href="#"onClick={(e)=>{
            if (select('#navbar').classList.contains('navbar-mobile')) {
              e.preventDefault()
              e.currentTarget.nextElementSibling.classList.toggle('dropdown-active')
            }
          }}><span>Hpl</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              {MenuProduct}
            </ul>
          </li> */}
          <li className="dropdown"><a href="#"onClick={(e)=>{
            if (select('#navbar').classList.contains('navbar-mobile')) {
              e.preventDefault()
              e.currentTarget.nextElementSibling.classList.toggle('dropdown-active')
            }
          }}><span>Our Brand</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
            <li  ><NavLink  to="Brand/Sanzolam" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Sezolam</NavLink></li>
            <li  ><NavLink  to="Brand/Door-Skin" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Door Skin</NavLink></li>
            <li  ><NavLink  to="Brand/Plain-Color" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Plain Color</NavLink></li>
            </ul>
          </li>
          
           <li><NavLink className="nav-link scrollto" to="Contact" onClick={(e)=>{
                topFunction();
              if (x.matches) { // If media query matches
              select('#navbar').classList.toggle('navbar-mobile')
              }
          }}>Contact</NavLink></li>
            <li id="google_translate_element" className="nav-link m-2" ></li>
        </ul>
        <i id="menu" className="bi bi-list mobile-nav-toggle"onClick={(e)=>{
                select('#navbar').classList.toggle('navbar-mobile')
            }}>  </i>
      </nav>

    </div>
  </header>
        </>
    );
}

export default Header;